@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@0;1&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Inclusive Sans', sans-serif !important;
    font-family: 'Inter', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-rendering: optimizeLegibility !important;
}

.swiper-pagination-bullet{
    background: #ddd !important;

}
.swiper-pagination-bullet-active{
    background-color: white !important;
    border: 1px solid rgb(81, 85, 85) !important;
}
::-webkit-scrollbar{
    display: none;
}
.box{
    min-height: 400px !important;
}

.list ol {
    list-style: circle !important;
}

.img{
    position: relative;
}
.img::before{
    content: '';
    display: block;
    z-index: 20;
    position: absolute;
    top: -12px;
    right: -12px;
    height: 24px;
    aspect-ratio: 1;
    background-color: red;
    border-radius: 50%;
}

.map *{
    width: 100% !important;
}